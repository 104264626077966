/** @format */

import { useMutation } from "@apollo/client";
import { ENUM_CUSTOMER_SOURCE, ENUM_PRODUCT_NAMES } from "@prisma/client";
import { SIGN_UP } from "@roadflex/graphql";
import { useSignup } from "@roadflex/react-hooks-www";
import { SignupValues } from "@roadflex/types";
import { setCookie } from "@roadflex/web-lib";
import getConfig from "next/config";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SignUpUI from "./signup.ui";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_APP_URL, NEXT_PUBLIC_FACTOR_URL, APP_ENV } =
  publicRuntimeConfig;

declare global {
  interface Window {
    dataLayer: Record<string, string>[];
  }
}

export function SignUp({ productName }: { productName: ENUM_PRODUCT_NAMES }) {
  const router = useRouter();
  // eslint-disable-next-line dot-notation
  const referral = router.query["referral"] as string;
  // eslint-disable-next-line dot-notation
  const identifier = router.query["identifier"] as string;
  // eslint-disable-next-line dot-notation
  const partnerName = router.query["partner"] as string;
  // eslint-disable-next-line dot-notation
  const email = router.query["email"] as string;
  const [signUpFn] = useMutation(SIGN_UP);
  const [showOtpModal, setShowOtpModal] = React.useState(false);
  const [showSignupConfirmationModal, setShowSignupConfirmationModal] =
    React.useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onOtpSubmit = async (value: SignupValues) => {
    try {
      setSubmitting(true);
      const res = await signUpFn({
        variables: {
          data: {
            firstName: value.firstName.trim(),
            lastName: value.lastName.trim(),
            email: value.email.trim(),
            phoneNumber: value.phoneNumber.trim().match(/\d+/g)?.join(""),
            businessName: value.businessName.trim(),
            customerSource: value.customerSource,
            referral:
              typeof value.referral === "string" ? value.referral.trim() : "",
            productName: value.productName,
            otp: value.otp,
            emailOtp: value.emailOtp,
            identifier: value.identifier || "",
          },
        },
      });
      const { authToken } = res?.data?.signup || {};
      setCookie("token", authToken, 30);
      if (productName === ENUM_PRODUCT_NAMES.FACTOR) {
        router.push({
          pathname: `${NEXT_PUBLIC_FACTOR_URL}/onboard`,
        });
        return;
      }
      router.push({
        pathname: `${NEXT_PUBLIC_APP_URL}/onboard`,
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "formSubmission",
        formType: "Signup",
        formPosition: value.email.trim(),
      });
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        setSubmitting(false);
      }
    }
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    setTouched,
    ...rest
  } = useSignup({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      businessName: "",
      customerSource: partnerName || ENUM_CUSTOMER_SOURCE.FACEBOOK,
      referral: "",
      productName,
      otp: "",
      emailOtp: "",
      identifier: "",
    },
    onSubmit(error) {
      if (error) {
        toast.error(error.message);
        return;
      }
      setShowSignupConfirmationModal(false);
      if (APP_ENV === "production") {
        setShowOtpModal(true);
      } else {
        onOtpSubmit(values);
      }
    },
  });

  const confirmSignup = () => {
    handleSubmit();
  };

  useEffect(() => {
    if (identifier) {
      setFieldValue("identifier", identifier);
    }
    if (referral) {
      setFieldValue("referral", referral);
    }
    if (email) {
      setFieldValue("email", email);
    }
    if (partnerName) {
      if (partnerName === "fleetup") {
        setFieldValue("customerSource", ENUM_CUSTOMER_SOURCE.FLEETUP);
      }
      if (partnerName === "advantageone") {
        setFieldValue("customerSource", ENUM_CUSTOMER_SOURCE.ADVANTAGEONE);
      }
      if (partnerName === "nalp") {
        setFieldValue("customerSource", ENUM_CUSTOMER_SOURCE.NALP);
      }
      if (partnerName === "npma") {
        setFieldValue("customerSource", ENUM_CUSTOMER_SOURCE.NPMA);
      }
    }
  }, [referral, email, partnerName, setFieldValue, identifier]);

  return (
    <SignUpUI
      {...{
        productName,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        setTouched,
        setFieldValue,
        showOtpModal,
        setShowOtpModal,
        showSignupConfirmationModal,
        setShowSignupConfirmationModal,
        confirmSignup,
        onOtpSubmit,
        otpSubmitting: submitting,
        ...rest,
      }}
    />
  );
}

export default SignUp;
