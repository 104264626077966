/* eslint-disable max-lines */
/** @format */
/* Structure of file:
    Part I: Landing Page
      1. Constants (no exporting allowed)
      2. Individual fields (no exporting allowed)
      3. Validation Schemas
    Part II: App
      1. Constants (no exporting allowed)
      2. Individual fields (no exporting allowed)
      3. Validation Schemas
    Part III: Admin
      1. Constants (no exporting allowed)
      2. Individual fields (no exporting allowed)
      3. Validation Schemas
    Part IV: Driver App
      1. Constants (no exporting allowed)
      2. Individual fields (no exporting allowed)
      3. Validation Schemas
 */

import {
  ENUM_AUTO_RELOAD_TYPE,
  ENUM_FUEL_TYPE,
  ENUM_MAIL_TYPE,
  ENUM_NOTIFICATION_TYPE,
  ENUM_TELEMATICS_SECURITY,
} from "@prisma/client";
import { differenceInYears, isValid, parse } from "date-fns";
import * as Yup from "yup";
import { SchemaLike } from "yup/lib/types";

//Part I Landing page (Constants)
//Part I: Landing page (Individual fields)
const FILE_SIZE = 1 * 1024 * 1024;
const MAX_FILES = 3;
const workEmail = Yup.string()
  .email("Email invalid")
  .required("Work email required");
const workEmailOptional = Yup.string().email("Email invalid");
const firstName = Yup.string()
  .min(2, "At least 2 characters")
  .max(40, "At most 40 characters")
  .required("First name required")
  .matches(/^(?!\s*$).+/, "First name cannot be empty or contain only spaces");
const lastName = Yup.string()
  .min(2, "At least 2 characters")
  .max(40, "At most 40 characters")
  .required("Last name required")
  .matches(/^(?!\s*$).+/, "Last name cannot be empty or contain only spaces");
const fullName = Yup.string()
  .min(4, "At least 4 characters")
  .max(40, "At most 40 characters")
  .required("Full name required")
  .matches(/^(?!\s*$).+/, "Name cannot be empty or contain only spaces");
const description = Yup.string()
  .min(3, "At least 3 characters")
  .max(40, "At most 40 characters")
  .required("Description required")
  .matches(/^(?!\s*$).+/, "Description cannot be empty or contain only spaces");
const roleOrTitle = Yup.string()
  .min(3, "At least 3 characters")
  .max(40, "At most 40 characters")
  .required("Role/title required")
  .matches(/^(?!\s*$).+/, "Role/title cannot be empty or contain only spaces");
const phoneNumber = Yup.string()
  .required("Phone number required")
  .test("checkPhoneNumber", "Phone number invalid", (value) => {
    return value?.trim().match(/\d+/g)?.join("").length === 10;
  })
  .matches(/^\([2-9][\d]{2}\) [\d]{3}-[\d]{4}$/, "Phone number invalid");
const phoneNumberOptional = Yup.string()
  .test("checkPhoneNumber", "Phone number invalid", (value) => {
    if (!value || value.length === 0) return true;
    return value?.trim().match(/\d+/g)?.join("").length === 10;
  })
  .matches(/^\([2-9][\d]{2}\) [\d]{3}-[\d]{4}$|^$/, "Phone number invalid");
const businessName = Yup.string()
  .min(3, "At least 3 characters")
  .max(40, "At most 40 characters")
  .required("Company name required")
  .matches(/\D/, "Company name cannot contain numbers only")
  .matches(
    /^(?!\s*$).+/,
    "Company name cannot be empty or contain only spaces",
  );
const cardName = Yup.string()
  .max(24, "At most 24 characters")
  .required("Card name required")
  .matches(/^[a-zA-Z ]*$/, "Special characters or numbers not allowed");
const fuel = Yup.string()
  .required("Fuel & maintenance spend per week required")
  .test("test", "Fuel & maintenance spend per week required", (value) => {
    return (value?.trim().match(/\d+/g)?.join("").length || 0) >= 1;
  });
const businessType = Yup.string().required("Business type required");
const industryType = Yup.string().required("Industry required");
const ssn = Yup.string()
  .required("SSN required")
  .matches(/^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/, "SSN invalid");
const fleetNum = Yup.number()
  .required("Fleet size required")
  .test(
    "between1and5000",
    "Fleet size should be between 1 and 5000",
    (value) => {
      if (value && value >= 1 && value <= 5000) {
        return true;
      }
      return false;
    },
  );
const annualBusinessRevenue = Yup.string()
  .required("Annual Business Revenue is required")
  .test("test", "Annual Business Revenue is required", (value) => {
    return (value?.trim().match(/\d+/g)?.join("").length || 0) >= 1;
  });
const ein = Yup.string()
  .required("EIN required")
  .test("checknumber", "EIN invalid", (value) => {
    return value?.replace(/\D+/g, "").length === 9;
  });
const irsLetter = Yup.mixed()
  .required("IRS Letter required")
  .test("checkFileSize", "File too large", (value) => {
    return value?.size <= FILE_SIZE;
  })
  .test("checkFileSize", "File empty", (value) => {
    return value?.size !== 0;
  })
  .test("checkExtension", "File extension not supported", (value) => {
    if (value) {
      const extension = value.name.split(".").pop();
      return ["pdf"].includes(extension);
    }
    return true;
  });
const fleetType = Yup.string().required("Fleet type required");
const personaFleetLicense = Yup.mixed()
  .required(
    "Failed to fetch the license due to an error, please try verifying the license again.",
  )
  .test(
    "checkFileSize",
    "Failed to fetch the license due to an error, please try verifying the license again.",
    (value) => {
      return value?.size !== 0;
    },
  );
const bankStatements = Yup.mixed()
  .test("checkSizeOfEachFile", "File too large", (value) => {
    if (value) {
      const files = [...value];
      return files.every((file) => file?.size <= FILE_SIZE);
    }
    return true;
  })
  .test("checkFileSize", "File empty", (value) => {
    if (value) {
      const files = [...value];
      return files.every((file) => file?.size !== 0);
    }
    return true;
  })
  .test("checkMaxFiles", "Please upload 3 files", (value) => {
    if (value) {
      return value.length === MAX_FILES;
    }
    return true;
  })
  .test("checkExtension", "File extension not supported", (value) => {
    if (value) {
      const files = [...value];
      return files.every((file) => {
        const extension = file.name.split(".").pop();
        return ["pdf"].includes(extension);
      });
    }
    return true;
  });
const streetAddress = Yup.string()
  .required("Street address required")
  .max(30, "At most 30 characters")
  .matches(/^[a-zA-Z0-9\s,.'-]{3,}$/, "Street address invalid")
  .matches(
    /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i,
    "PO Box not allowed",
  );
const streetDetails = Yup.string()
  .max(30, "At most 30 characters")
  .matches(
    /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i,
    "PO Box not allowed",
  );
const city = Yup.string()
  .required("City required")
  .matches(/^\s*[a-zA-Z]+(?:[\s-][a-zA-Z]+)*\s*$/, "City invalid");

const state = Yup.string()
  .required("State required")
  .matches(/([A-Za-z]{2})/, "State invalid");

const zip = Yup.string()
  .required("Zip required")
  .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Zip invalid");

const dateOfBirth = Yup.string()
  .required("Date of birth is required")
  .test("is-valid-format", "Date must be in MM/DD/YYYY format", (value) => {
    if (!value) return false;
    const parsedDate = parse(value, "MM/dd/yyyy", new Date());
    return isValid(parsedDate); // Ensures the date is valid
  })
  .test("is-under-max-age", "You must be less than 100 years old", (value) => {
    if (!value) return false;
    const parsedDate = parse(value, "MM/dd/yyyy", new Date());
    const age = differenceInYears(new Date(), parsedDate);
    return age <= 100; // Ensures the person is younger than 100 years
  });

//Part I: Landing page (Validation schemas)
export const wwwEmailValidationSchema = Yup.object().shape({
  email: workEmail.required("Work email required"),
});
export const wwwEnterpriseApplicationValidationSchema = Yup.object().shape({
  firstName,
  lastName,
  email: workEmail,
  phoneNumber,
  businessName,
  cardName,
  fuel,
  type: businessType,
  companyType: industryType,
  dot: Yup.string(),
  businessAddress: Yup.object().shape({
    streetAddress,
    streetDetails,
    state,
    city,
    zip,
  }),
  deliveryAddress: Yup.mixed().when("differentDeliveryAddress", {
    is: (differentDeliveryAddress: string) => {
      return differentDeliveryAddress;
    },
    then: Yup.object().shape({
      streetAddress,
      streetDetails,
      state,
      city,
      zip,
    }),
  }),
  fleetNum,
  ein,
  ssn,
  statements: bankStatements,
});
export const wwwSignupValidationSchema = Yup.object().shape({
  firstName,
  lastName,
  email: workEmail,
  phoneNumber,
  businessName,
  customerSource: Yup.string().required("Customer source required"),
});
export const wwwGenericFormSchema = Yup.object().shape({
  landingPageMailType: Yup.string().required(),
  email: workEmail,
  name: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return landingPageMailType === ENUM_MAIL_TYPE.CONTACT_US;
    },
    then: fullName,
  }),
  firstName: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return (
        landingPageMailType === ENUM_MAIL_TYPE.AFFILIATE_PROGRAM ||
        landingPageMailType === ENUM_MAIL_TYPE.BROKER ||
        landingPageMailType === ENUM_MAIL_TYPE.SAVINGS ||
        landingPageMailType === ENUM_MAIL_TYPE.PARTNERSHIPS ||
        landingPageMailType === ENUM_MAIL_TYPE.WHITE_PAPER_SUBSCRIBER
      );
    },
    then: firstName,
  }),
  lastName: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return (
        landingPageMailType === ENUM_MAIL_TYPE.AFFILIATE_PROGRAM ||
        landingPageMailType === ENUM_MAIL_TYPE.BROKER ||
        landingPageMailType === ENUM_MAIL_TYPE.SAVINGS ||
        landingPageMailType === ENUM_MAIL_TYPE.PARTNERSHIPS ||
        landingPageMailType === ENUM_MAIL_TYPE.WHITE_PAPER_SUBSCRIBER
      );
    },
    then: lastName,
  }),
  phoneNumber: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return (
        landingPageMailType === ENUM_MAIL_TYPE.CONTACT_US ||
        landingPageMailType === ENUM_MAIL_TYPE.SAVINGS ||
        landingPageMailType === ENUM_MAIL_TYPE.WHITE_PAPER_SUBSCRIBER
      );
    },
    then: phoneNumber,
  }),
  spend: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return landingPageMailType === ENUM_MAIL_TYPE.CONTACT_US;
    },
    then: fuel,
  }),
  vehicles: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return landingPageMailType === ENUM_MAIL_TYPE.CONTACT_US;
    },
    then: fleetNum,
  }),
  companyName: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return (
        landingPageMailType === ENUM_MAIL_TYPE.CONTACT_US ||
        landingPageMailType === ENUM_MAIL_TYPE.AFFILIATE_PROGRAM ||
        landingPageMailType === ENUM_MAIL_TYPE.BROKER ||
        landingPageMailType === ENUM_MAIL_TYPE.SAVINGS ||
        landingPageMailType === ENUM_MAIL_TYPE.PARTNERSHIPS ||
        landingPageMailType === ENUM_MAIL_TYPE.WHITE_PAPER_SUBSCRIBER
      );
    },
    then: businessName,
  }),
  role: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return (
        landingPageMailType === ENUM_MAIL_TYPE.AFFILIATE_PROGRAM ||
        landingPageMailType === ENUM_MAIL_TYPE.BROKER ||
        landingPageMailType === ENUM_MAIL_TYPE.PARTNERSHIPS ||
        landingPageMailType === ENUM_MAIL_TYPE.WHITE_PAPER_SUBSCRIBER
      );
    },
    then: roleOrTitle,
  }),
  yearsInBusiness: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return (
        landingPageMailType === ENUM_MAIL_TYPE.AFFILIATE_PROGRAM ||
        landingPageMailType === ENUM_MAIL_TYPE.BROKER ||
        landingPageMailType === ENUM_MAIL_TYPE.PARTNERSHIPS
      );
    },
    then: Yup.string().required("Number of years in business required"),
  }),
  companyWebsite: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return (
        landingPageMailType === ENUM_MAIL_TYPE.AFFILIATE_PROGRAM ||
        landingPageMailType === ENUM_MAIL_TYPE.BROKER ||
        landingPageMailType === ENUM_MAIL_TYPE.PARTNERSHIPS
      );
    },
    then: Yup.string().required("Company website required"),
  }),
  fleetSize: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return landingPageMailType === ENUM_MAIL_TYPE.WHITE_PAPER_SUBSCRIBER;
    },
    then: Yup.string().required("Fleet size is required"),
  }),
  companyType: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return landingPageMailType === ENUM_MAIL_TYPE.WHITE_PAPER_SUBSCRIBER;
    },
    then: Yup.string().required("Type of industry is required"),
  }),
  description: Yup.mixed().when("landingPageMailType", {
    is: (landingPageMailType: string) => {
      return (
        landingPageMailType === ENUM_MAIL_TYPE.AFFILIATE_PROGRAM ||
        landingPageMailType === ENUM_MAIL_TYPE.BROKER ||
        landingPageMailType === ENUM_MAIL_TYPE.PARTNERSHIPS
      );
    },
    then: description,
  }),
});
export const onboardBankStatementsSchema = (
  numberOfUploadedStatements: number,
) =>
  Yup.object().shape(
    {
      statements: Yup.mixed()
        .when((value) => {
          if (value.length >= 1) {
            return bankStatements;
          }
          return undefined as unknown as SchemaLike;
        })
        .when(["plaidConnect", "docsReupload"], {
          is: (plaidConnect: boolean, docsReupload: boolean) => {
            return (
              !plaidConnect &&
              (numberOfUploadedStatements === 0 || docsReupload)
            );
          },
          then: Yup.mixed().test(
            "eitherOne",
            "Please upload 3 files or connect to bank account",
            (value) => {
              if (value.length >= 1) {
                return true;
              }
              return false;
            },
          ),
        }),
      plaidConnect: Yup.mixed().when("statements", {
        is: (statements: unknown[]) => {
          return !statements?.length;
        },
        then: Yup.bool(),
      }),
    },
    [
      ["statements", "plaidConnect"],
      ["plaidConnect", "statements"],
    ],
  );
export const onboardBusinessInformationSchema = Yup.object().shape({
  title: businessName,
  cardName,
  // fuel,
  type: businessType,
  companyType: industryType,
  yearsOfOperation: Yup.string()
    .required("Years of operation is required")
    .matches(/^[0-9]+$/, "Must be only digits"),
  ein,
  irsLetter: Yup.mixed()
    .when((value) => {
      //if value and uploadlicense does not exist then apply validation
      if (value) {
        return irsLetter;
      }
      return undefined as unknown as SchemaLike;
    })
    .when("irsLetterReupload", {
      //if trying to reupload then apply validation
      is: (irsLetterReupload: boolean) => {
        return irsLetterReupload;
      },
      then: irsLetter,
    }),
});
export const onboardFleetInformationSchema = Yup.object().shape({
  fleetNum,
  ein,
  fleetType,
});
export const onboardPersonalInformationSchema = Yup.object().shape({
  firstName,
  lastName,
  phoneNumber,
  ssn,
  fleetLicense: personaFleetLicense,
  fleetLicenseBack: personaFleetLicense,
  dateOfBirth,
});
export const onboardTermsOfServiceSchema = Yup.object().shape({
  authorizedRepresentative: Yup.boolean()
    .oneOf([true], "You must be an authorized representative")
    .required("Required"),
  agreeToRoadFlexTerms: Yup.boolean()
    .oneOf([true], "You must agree to the RoadFlex terms")
    .required("Required"),
});
export const onboardAdditionalBusinessInfoSchema = Yup.object().shape({
  businessAddress: Yup.object().shape({
    streetAddress,
    streetDetails,
    state,
    city,
    zip,
  }),
  deliveryAddress: Yup.mixed().when("differentDeliveryAddress", {
    is: (differentDeliveryAddress: string) => {
      return differentDeliveryAddress;
    },
    then: Yup.object().shape({
      streetAddress,
      streetDetails,
      state,
      city,
      zip,
    }),
  }),
  fuel,
  fleetNum,
  fleetType,
  annualBusinessRevenue,
});
export const onboardAddressSchema = Yup.object().shape({
  homeAddress: Yup.object().shape({
    streetAddress,
    streetDetails,
    state,
    city,
    zip,
  }),
});
export const appAccountSettingsAddressSchema = Yup.object().shape({
  businessAddress: Yup.object().shape({
    streetAddress,
    streetDetails,
    state,
    city,
    zip,
  }),
  deliveryAddress: Yup.mixed().when("differentDeliveryAddress", {
    is: (differentDeliveryAddress: string) => {
      return differentDeliveryAddress;
    },
    then: Yup.object().shape({
      streetAddress,
      streetDetails,
      state,
      city,
      zip,
    }),
  }),
});
//Part II: App (Constants)
const password = Yup.string()
  .min(8, "At least 8 characters")
  .max(32, "At most 32 characters")
  .required("Password required");
//Part II: App (Individual fields)
const make = Yup.string().max(20, "At most 20 characters");
const model = Yup.string().max(20, "At most 20 characters");
const year = Yup.string().matches(/^[0-9]{4}$/, "Must be in YYYY format");
const vin = Yup.string().matches(
  /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/,
  "VIN invalid",
);
const tankCapacity = Yup.number()
  .typeError("Tank capacity must be a number")
  .test(
    "tankCapacityTest",
    "Tank capacity should be between 8 and 800 gallons",
    (value) => {
      if (value && value >= 8 && value <= 800) {
        return true;
      }
      return false;
    },
  );
const licensePlate = Yup.string().matches(
  /^[A-Za-z0-9-]{1,10}$/,
  "License plate invalid",
);
const strictPassword = Yup.string()
  .min(8, "At least 8 characters")
  .max(32, "At most 32 characters")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d].{7,}$/,
    "At least 8 characters, 1 uppercase, 1 lowercase and 1 number",
  );
const retypeStrictPassword = Yup.string().oneOf(
  [Yup.ref("password"), null],
  "Passwords must match",
);

const policyName = Yup.string()
  .min(2, "At least 2 characters")
  .max(15, "At most 30 characters")
  .required("Policy name required");

//Part II: App (Validation schemas)
//Additional account page (for child users)
export const genericSignInValidationSchema = Yup.object().shape({
  email: workEmail.required("Email required"),
  password: password,
});
export const genericPasswordSchema = Yup.object().shape({
  password: strictPassword.required("Password required"),
  retypePassword: retypeStrictPassword.required(
    "Password confirmation required",
  ),
});
export const genericPhoneSchema = Yup.object().shape({
  phoneNumber,
});
export const appChildUserCreationSchema = Yup.object().shape({
  newPhoneNumber: phoneNumber,
});
export const appChildUserAccountSchema = Yup.object().shape({
  password: strictPassword.required("Password required"),
  retypePassword: retypeStrictPassword.required("Required"),
  phoneNumber: phoneNumber,
  otp: Yup.mixed().when("phoneNumber", {
    is: (number: any) => {
      return number?.length;
    },
    then: Yup.string()
      .required("Verification code required")
      .length(6, "Verification code must be 6 digits"),
  }),
});
export const appMissingDetailsSchema = Yup.object().shape({
  ssn,
  ein,
});
//Account tags page
export const appAccountTagValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "At least 2 characters")
    .max(20, "At most 20 characters"),
  description: Yup.string().max(50, "At most 50 characters"),
});
//Policies page
export const appPolicyValidationSchema = (readGlobalSetting: {
  dailyLimit: number;
  transactionLimit: number;
}) =>
  Yup.object().shape({
    name: policyName,
    dailyLimit: Yup.number()
      .positive("Must be greater than 0")
      .test(
        "Is GlobalSetting Extented",
        "Lower your limit",
        (value) => (value || 0) <= readGlobalSetting?.dailyLimit / 100,
      ),
    weeklyLimit: Yup.number().positive("Must be greater than 0").notRequired(),
    monthlyLimit: Yup.number().positive("Must be greater than 0").notRequired(),
    transactionLimit: Yup.number()
      .positive("Must be greater than 0")
      .test(
        "Is GlobalSetting Extented",
        "Lower your limit",
        (value) => (value || 0) <= readGlobalSetting?.transactionLimit / 100,
      ),
    odometerSecurity: Yup.mixed().when("smsSecurity", {
      //if trying to reupload then apply validation
      is: (smsSecurity: boolean) => {
        return !smsSecurity;
      },
      then: Yup.mixed()
        .required("Card unlock feature must be activated")
        .oneOf([false], "Card unlock feature must be activated"),
    }),
    merchants: Yup.array().of(
      Yup.object().shape({
        numberOfPurchaseLimit: Yup.mixed().when(
          "numberOfPurchaseLimitToggleOn",
          {
            //if on then apply validation
            is: (numberOfPurchaseLimitToggleOn: boolean) => {
              return numberOfPurchaseLimitToggleOn;
            },
            then: Yup.number()
              .typeError("Must be a number")
              .positive("Must be greater than 0")
              .required("Limited required"),
          },
        ),
        totalSpendLimit: Yup.mixed().when("totalSpendLimitToggleOn", {
          //if on then apply validation
          is: (totalSpendLimitToggleOn: boolean) => {
            return totalSpendLimitToggleOn;
          },
          then: Yup.number()
            .typeError("Must be a number")
            .positive("Must be greater than 0")
            .required("Limit required"),
        }),
        requireReceiptAmount: Yup.mixed().when("requireReceiptToggleOn", {
          //if on then apply validation
          is: (requireReceiptToggleOn: boolean) => {
            return requireReceiptToggleOn;
          },
          then: Yup.number()
            .typeError("Must be a number")
            .moreThan(-1, "Must be 0 or greater than 0")
            .required("Limit required"),
        }),
      }),
    ),
  });
//Cards page
export const appCardOrderSchema = (
  orderedCardsLength: number,
  numberOfCards: number,
) =>
  Yup.object().shape({
    noOfCards: Yup.string()
      .required("Minimum of 1 card required")
      .test("cardOrderMinimum", "Minimum of 1 card required", (value) => {
        if (parseInt(value || "", 10) > 0) {
          return true;
        }
        return false;
      })
      .test(
        "cardOrderMaximum",
        "Unable to order more than the maximum limit",
        (value) => {
          return (
            orderedCardsLength + parseInt(value || "", 10) <= numberOfCards
          );
        },
      ),
  });

export const cardDetailsUpdateSchema = (
  adminCardSettingData: { dailyLimit: number; transactionLimit: number },
  vehiclesList: {
    id: string;
    fleetName: string;
    createdAt: Date;
    eldOrgName: string;
    fuelType: ENUM_FUEL_TYPE;
    tankCapacity: number;
  }[],
  driverList: {
    id: string;
    createdAt: Date;
    name: string;
    isPhoneVerified?: boolean;
  }[],
) =>
  Yup.object().shape({
    dailyLimit: Yup.number()
      .positive("The number must be greater than 0")
      .test(
        "Is global setting exceeded",
        "Lower your limit",
        (value) => (value || 0) <= adminCardSettingData?.dailyLimit / 100,
      ),
    weeklyLimit: Yup.number()
      .positive("The number must be greater than 0")
      .notRequired(),
    monthlyLimit: Yup.number()
      .positive("The number must be greater than 0")
      .notRequired(),
    transactionLimit: Yup.number()
      .positive("The number must be greater than 0")
      .test(
        "Is global setting exceeded",
        "Lower your limit",
        (value) => (value || 0) <= adminCardSettingData?.transactionLimit / 100,
      ),
    smsSecurity: Yup.mixed().when("driverId", (driverId, schema) => {
      const driver = driverList.find((d) => d.id === driverId);
      if (driver) {
        if (driver.isPhoneVerified) {
          // If driver is found and isPhoneVerified true
          return schema; // No additional validation
        }
        // If isPhoneVerified false
        return Yup.boolean()
          .required(
            "A verified driver needs to be linked to this card to activate this feature.",
          )
          .oneOf(
            [false],
            "A verified driver needs to be linked to this card to activate this feature.",
          );
      }
      // If driver is not found
      return Yup.boolean()
        .required(
          "A verified driver needs to be linked to this card to activate this feature.",
        )
        .oneOf(
          [false],
          "A verified driver needs to be linked to this card to activate this feature.",
        );
    }),
    odometerSecurity: Yup.mixed()
      .when("smsSecurity", {
        is: (smsSecurity: boolean) => {
          return !smsSecurity;
        },
        then: Yup.mixed()
          .required(
            "Card unlock feature should be turned on to activate this feature",
          )
          .oneOf(
            [false],
            "Card unlock feature should be turned on to activate this feature",
          ),
      })
      .when("vehicleId", {
        is: (vehicleId: string) => {
          return !vehicleId;
        },
        then: Yup.boolean()
          .required(
            "A vehicle needs to be linked to this card to activate this feature.",
          )
          .oneOf(
            [false],
            "A vehicle needs to be linked to this card to activate this feature.",
          ),
      }),
    enableSmsReceiptUpload: Yup.mixed().when("driverId", {
      is: (driverId: string) => {
        return !driverId;
      },
      then: Yup.boolean()
        .required(
          "A driver needs to be linked to this card to activate this feature.",
        )
        .oneOf(
          [false],
          "A driver needs to be linked to this card to activate this feature.",
        ),
    }),
    requireJobID: Yup.mixed().when("driverId", {
      is: (driverId: string) => {
        return !driverId;
      },
      then: Yup.boolean()
        .required(
          "A driver needs to be linked to this card to activate this feature.",
        )
        .oneOf(
          [false],
          "A driver needs to be linked to this card to activate this feature.",
        ),
    }),
    fuelTypeSecurity: Yup.mixed().when("vehicleId", (vehicleId, schema) => {
      const vehicle = vehiclesList.find((v) => v.id === vehicleId);
      if (vehicle) {
        if (vehicle.fuelType != null) {
          // If vehicle is found and tankCapacity is not null
          return schema; // No additional validation
        }
        // If vehicle is found but tankCapacity is null
        return Yup.boolean()
          .required(
            "The fuel type for the vehicle linked to this card must be provided.",
          )
          .oneOf(
            [false],
            "The fuel type for the vehicle linked to this card must be provided.",
          );
      }
      // If vehicle is not found
      return Yup.boolean()
        .required(
          "A vehicle needs to be linked to this card to activate this feature.",
        )
        .oneOf(
          [false],
          "A vehicle needs to be linked to this card to activate this feature.",
        );
    }),
    tankCapacitySecurity: Yup.mixed().when("vehicleId", (vehicleId, schema) => {
      const vehicle = vehiclesList.find((v) => v.id === vehicleId);

      if (vehicle) {
        if (vehicle.tankCapacity != null) {
          // If vehicle is found and tankCapacity is not null
          return schema; // No additional validation
        }
        // If vehicle is found but tankCapacity is null
        return Yup.boolean()
          .required(
            "The tank capacity for the vehicle linked to this card must be provided.",
          )
          .oneOf(
            [false],
            "The tank capacity for the vehicle linked to this card must be provided.",
          );
      }
      // If vehicle is not found
      return Yup.boolean()
        .required(
          "A vehicle needs to be linked to this card to activate this feature.",
        )
        .oneOf(
          [false],
          "A vehicle needs to be linked to this card to activate this feature.",
        );
    }),
    telematicsSecurity: Yup.mixed().when("vehicleId", (vehicleId, schema) => {
      const vehicle = vehiclesList.find((v) => v.id === vehicleId);
      if (vehicle) {
        if (vehicle.eldOrgName === null) {
          return Yup.mixed().test(
            "telematicsSecurity",
            "Telematics integration required.",
            (value) =>
              ![
                ENUM_TELEMATICS_SECURITY.MONITOR,
                ENUM_TELEMATICS_SECURITY.BLOCK,
              ].includes(value),
          );
        }
      }
      // If vehicle is not found
      return Yup.mixed().test(
        "telematicsSecurity",
        "A vehicle with telematics integration needs to be linked to this card to activate this feature.",
        (value) =>
          ![
            ENUM_TELEMATICS_SECURITY.MONITOR,
            ENUM_TELEMATICS_SECURITY.BLOCK,
          ].includes(value),
      );
    }),
    suspiciousFuelingSecurity: Yup.mixed().when(
      "vehicleId",
      (vehicleId, schema) => {
        const vehicle = vehiclesList.find((v) => v.id === vehicleId);

        if (vehicle) {
          if (vehicle.eldOrgName !== null) {
            if (vehicle.tankCapacity != null) {
              // If vehicle is found and tankCapacity is not null
              return schema; // No additional validation
            }
            // If vehicle is found but tankCapacity is null
            return Yup.boolean()
              .required(
                "The tank capacity for the vehicle linked to this card must be provided.",
              )
              .oneOf(
                [false],
                "The tank capacity for the vehicle linked to this card must be provided.",
              );
          }
          return Yup.boolean()
            .required("Telematics integration required.")
            .oneOf([false], "Telematics integration required.");
        }
        // If vehicle is not found
        return Yup.boolean()
          .required(
            "A vehicle needs to be linked to this card to activate this feature.",
          )
          .oneOf(
            [false],
            "A vehicle needs to be linked to this card to activate this feature.",
          );
      },
    ),
    merchants: Yup.array().of(
      Yup.object().shape({
        numberOfPurchaseLimit: Yup.mixed().when(
          "numberOfPurchaseLimitToggleOn",
          {
            //if on then apply validation
            is: (numberOfPurchaseLimitToggleOn: boolean) => {
              return numberOfPurchaseLimitToggleOn;
            },
            then: Yup.number()
              .typeError("You must specify a number")
              .positive("The number must be greater than 0")
              .required("You must specify a number"),
          },
        ),
        totalSpendLimit: Yup.mixed().when("totalSpendLimitToggleOn", {
          //if on then apply validation
          is: (totalSpendLimitToggleOn: boolean) => {
            return totalSpendLimitToggleOn;
          },
          then: Yup.number()
            .typeError("You must specify the limit")
            .positive("The number must be greater than 0")
            .required("You must specify the limit"),
        }),
        requireReceiptAmount: Yup.mixed().when("requireReceiptToggleOn", {
          //if on then apply validation
          is: (requireReceiptToggleOn: boolean) => {
            return requireReceiptToggleOn;
          },
          then: Yup.number()
            .typeError("You must specify the limit")
            .moreThan(-1, "The number must be 0 or more than 0")
            .required("You must specify the limit"),
        }),
      }),
    ),
  });

export const appUpdateCardPinSchema = Yup.object().shape({
  pin: Yup.string()
    .required("Pin required")
    .test("4DigitsLong", "Pin should be 4 digits", (value) => {
      if (
        value &&
        parseInt(value || "", 10) >= 1000 &&
        parseInt(value || "", 10) <= 9999
      ) {
        return true;
      }
      return false;
    }),
});
//Vehicles page
export const appVehicleValidationSchema = Yup.object().shape({
  vin,
  make,
  model,
  year,
  fleetName: Yup.string()
    .min(2, "At least 2 characters")
    .required("Vehicle name required"),
  licensePlate: licensePlate.required("Licence plate required"),
  tankCapacity: Yup.mixed().when((value) => {
    if (value) {
      return tankCapacity;
    }
    return Yup.number().notRequired().nullable();
  }),
});
export const appVehicleFileValidationSchema = Yup.object().shape({
  vehiclesFile: Yup.mixed()
    .required("Please upload the vehicles file")
    .test("checkFileSize", "File too large", (value) => {
      return value?.size <= FILE_SIZE;
    })
    .test("checkFileSizeEmpty", "File empty", (value) => {
      return value?.size !== 0;
    })
    .test("checkExtension", "File extension not supported", (value) => {
      if (value) {
        const extension = value.name.split(".").pop();
        return ["csv"].includes(extension);
      }
      return true;
    }),
});
//Drivers page
export const appDriverValidationSchema = Yup.object().shape({
  name: fullName,
  email: workEmailOptional,
  phoneNumber: Yup.mixed().when("inviteDriver", {
    is: (inviteDriver: boolean) => {
      return inviteDriver || false;
    },
    then: phoneNumber,
    otherwise: phoneNumberOptional,
  }),
  notes: Yup.string().max(50, "At most 50 characters"),
});
export const appDriverFileValidationSchema = Yup.object().shape({
  driversFile: Yup.mixed()
    .required("Please upload the drivers file")
    .test("checkFileSize", "File too large", (value) => {
      return value?.size <= FILE_SIZE;
    })
    .test("checkFileSizeEmpty", "File empty", (value) => {
      return value?.size !== 0;
    })
    .test("checkExtension", "File extension not supported", (value) => {
      if (value) {
        const extension = value.name.split(".").pop();
        return ["csv"].includes(extension);
      }
      return true;
    }),
});
//Payment methods page
export const appPaymentMethodsValidationSchema = Yup.object().shape({
  reloadType: Yup.string().required(),
  topUpAmount: Yup.mixed().when(["enabled"], {
    is: (enabled: boolean) => {
      return enabled;
    },
    then: Yup.string()
      .required()
      .test("minimum25", "Minimum $25 is required", (value) => {
        if (value) {
          return Number(value) * 100 >= 2500;
        }
        return true;
      }),
  }),
  belowAmount: Yup.mixed().when(["reloadType", "enabled"], {
    is: (reloadType: ENUM_AUTO_RELOAD_TYPE, enabled: boolean) => {
      return enabled && reloadType === ENUM_AUTO_RELOAD_TYPE.ON_BAL;
    },
    then: Yup.string()
      .required()
      .test("minimum1", "Minimum $1 is required", (value) => {
        if (value) {
          return Number(value) * 100 >= 100;
        }
        return true;
      }),
  }),
  reloadFrequency: Yup.mixed().when(["reloadType"], {
    is: (reloadType: ENUM_AUTO_RELOAD_TYPE) => {
      return reloadType === ENUM_AUTO_RELOAD_TYPE.ON_SCH;
    },
    then: Yup.string().required(),
  }),
});
//Multiple users page
export const appChildUserValidationSchema = Yup.object().shape({
  email: workEmail.required("Email required"),
  phoneNumber: phoneNumberOptional,
  roleName: Yup.string().required("Role required"),
  firstName: firstName.required("First name required"),
  lastName: lastName.required("Last name required"),
});
//Account settings page
export const appAccountSettingsChangeEmailSchema = Yup.object().shape({
  newPhoneNumber: phoneNumber,
  currentEmail: workEmail,
  newEmail: workEmail,
});
export const appAccountSettingsChangeCardNameSchema = Yup.object().shape({
  cardName,
});
//Referral page
export const appReferralSharingSchema = Yup.object().shape({
  phoneNumber: phoneNumberOptional,
  email: workEmailOptional,
});
//Parent company page
export const appParentCompanySchema = Yup.object().shape({
  password: strictPassword.required("Password required"),
  email: workEmail.required("Email required"),
  phoneNumber: phoneNumber.required("Phone number required"),
});

//Part III: Admin (Constants)
const OUTREACH_FILE_SIZE = 0.25 * 1024 * 1024;
const BLOG_FILE_SIZE = 0.3 * 1024 * 1024;
//Part III: Admin (Individual fields)
const templateName = Yup.string().required("Template name required");

const blogImage = (required: boolean) => {
  return Yup.mixed()
    .test("required", "Required", (value) => {
      if (required) {
        return value?.name !== "" && value;
      }
      return true;
    })
    .test("checkFileSize", "File must not exceed 300KB", (value) => {
      if (required) {
        return value?.size <= BLOG_FILE_SIZE;
      }
      return value?.name === "" ? true : value?.size < BLOG_FILE_SIZE;
    })
    .test("checkFileSize", "File empty", (value) => {
      if (required) {
        return value?.size !== 0;
      }
      return value?.name === "" ? true : value?.size !== 0;
    })
    .test("checkExtension", "File extension not supported", (value) => {
      if (value) {
        const extension = value.name.split(".").pop();
        if (required) {
          return ["jpg", "jpeg", "png"].includes(extension);
        }
        return value?.name === ""
          ? true
          : ["jpg", "jpeg", "png"].includes(extension);
      }
      return true;
    });
};

const blogImageSupport = Yup.mixed()
  .test("checkFileSize", "File must not exceed 300KB", (value) => {
    return value?.size <= BLOG_FILE_SIZE;
  })
  .test("checkFileSize", "File empty", (value) => {
    return value?.size !== 0;
  })
  .test("checkExtension", "File extension not supported", (value) => {
    if (value) {
      const extension = value.name.split(".").pop();
      return ["jpg", "jpeg", "png"].includes(extension);
    }
    return true;
  });
const discountRange = Yup.number()
  .min(0.01)
  .max(0.05)
  .test("maxDigitsAfterDecimal", "At most 2 decimal places", (value) =>
    Number.isInteger((value || 0) * 10 ** 2),
  );

const fuelDiscount = Yup.object().shape({
  creditFuelDiscount: discountRange,
  creditPlusFuelDiscount: discountRange,
  prepaidFuelDiscount: discountRange,
  prepaidPlusFuelDiscount: discountRange,
});

const bonus = Yup.object().shape({
  bonusAmount: Yup.number()
    .integer("Please enter an integer")
    .min(100)
    .max(200),
  fuelQuantity: Yup.number()
    .integer("Please enter an integer")
    .min(1000)
    .max(10000),
});
//Part III: Admin (Validation schemas)
export const adminAddressSchema = Yup.object().shape({
  streetAddress: streetAddress,
  streetDetails: streetDetails,
  state: state,
  city: city,
  zip: zip,
});
export const adminPrefillSchema = Yup.object().shape({
  firstName,
  lastName,
  email: workEmail,
  phoneNumber,
  businessName,
});
export const adminSmsEmailTest = Yup.object().shape({
  emailList: Yup.mixed().when("mode", {
    is: (mode: string) => {
      return mode === "Email";
    },
    then: Yup.array().of(workEmailOptional),
  }),
  phoneNumber: Yup.mixed().when("mode", {
    is: (mode: string) => {
      return mode === "SMS";
    },
    then: phoneNumberOptional,
  }),
});

//Admin: Notifications page
export const adminNotificationsWorkFlowSchema = Yup.object().shape({
  day: Yup.number().required("Day required").min(1).max(90),
  smsTemplate: Yup.mixed().when("notificationType", {
    is: (notificationType: ENUM_NOTIFICATION_TYPE) => {
      return notificationType.includes(ENUM_NOTIFICATION_TYPE.SMS);
    },
    then: Yup.string()
      .required("SMS template required")
      .max(120, "At most 120 characters"),
  }),
  emailId: Yup.mixed().when("notificationType", {
    is: (notificationType: ENUM_NOTIFICATION_TYPE) => {
      return notificationType.includes(ENUM_NOTIFICATION_TYPE.EMAIL);
    },
    then: workEmail,
  }),
});
export const adminNotificationsOutreachFileSchema = Yup.object().shape({
  description: description,
  outreachFile: Yup.mixed()
    .required("Please upload the outreach file")
    .test("checkFileSize", "File must not exceed 250KB", (value) => {
      return value?.size <= OUTREACH_FILE_SIZE;
    })
    .test("checkFileSize", "File empty", (value) => {
      return value?.size !== 0;
    })
    .test("checkExtension", "File extension not supported", (value) => {
      if (value) {
        const extension = value.name.split(".").pop();
        return ["csv"].includes(extension);
      }
      return true;
    }),
});
export const adminAssignMailType = Yup.object().shape({
  mailType: Yup.string().required("MailType is required"),
  templateName: Yup.string().required("Template is required"),
});

//Admin: Global settings page
export const adminUpdateFuelDiscount = Yup.object().shape({
  fuelDiscount: Yup.mixed().when("mode", {
    is: (mode: string) => {
      return mode === "fuelDiscount";
    },
    then: fuelDiscount,
  }),
  referralBonus: Yup.mixed().when("mode", {
    is: (mode: string) => {
      return mode === "referralBonus";
    },
    then: bonus,
  }),
  signupBonus: Yup.mixed().when("mode", {
    is: (mode: string) => {
      return mode === "signupBonus";
    },
    then: bonus,
  }),
});

export const adminNotificationsEmailTemplateSchema = Yup.object().shape({
  templateName,
  email: workEmail,
});
export const adminCustomerDashboardQuerySchema = Yup.object().shape({
  queryType: Yup.string().required(),
  email: Yup.mixed().when("queryType", {
    is: (queryType: string) => {
      return queryType === "email";
    },
    then: workEmail,
  }),
  phoneNumber: Yup.mixed().when("queryType", {
    is: (queryType: string) => {
      return queryType === "phoneNumber";
    },
    then: phoneNumber,
  }),
  businessName: Yup.mixed().when("queryType", {
    is: (queryType: string) => {
      return queryType === "businessName";
    },
    then: businessName,
  }),
});
export const adminBlogSchema = Yup.object().shape({
  title: Yup.string()
    .min(10, "At least 10 characters")
    .required("Blog title required"),
  summary: Yup.string()
    .min(20, "At least 20 characters")
    .required("Summary required"),
  blog: Yup.string().required("Blog body required"),
  rank: Yup.string().required("Rank required"),
  uploadedTitleImage: Yup.mixed().when("titleImage", {
    is: (titleImage: string) => {
      return !titleImage;
    },
    then: blogImage(true),
    otherwise: blogImage(false),
  }),
  uploadedSupportImage1: Yup.mixed().when((value) => {
    if (value && value.name !== "") {
      return blogImageSupport;
    }
    return undefined as unknown as SchemaLike;
  }),
  uploadedSupportImage2: Yup.mixed().when((value) => {
    if (value && value.name !== "") {
      return blogImageSupport;
    }
    return undefined as unknown as SchemaLike;
  }),
});
export const adminTeamSchema = Yup.object().shape({
  firstName: firstName,
  lastName: lastName,
  email: workEmail,
});

//Part IV Driver app (Validation Schemas)
export const driverAppEmailSchema = Yup.object().shape({
  email: workEmail,
});
export const IsCSR = typeof window !== "undefined";
