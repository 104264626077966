/** @format */

import { Dialog, Transition } from "@headlessui/react";
import { SignupValues } from "@roadflex/types";
import { FormikProps } from "formik";
import { Fragment, useEffect, useRef, useState } from "react";
import OtpInput from "react-otp-input";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = FormikProps<any> & {
  show: boolean;
  setShow: (value: boolean) => void;
  onOtpSubmit: (value: SignupValues) => void;
  otpSubmitting: boolean;
  resendCode?: () => void;
  fromSignUp?: boolean;
};

export function OTPModalOnboarding({
  show,
  setShow,
  values,
  handleSubmit,
  setFieldValue,
  onOtpSubmit,
  otpSubmitting,
  resendCode,
  fromSignUp = false,
}: Props) {
  const cancelButtonRef = useRef(null);

  const [hasErrored, setHasErrored] = useState(false);
  const [hasEmailOtpErrored, setHasEmailOtpErrored] = useState(false);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer]);

  const onSubmit = async () => {
    let isError = false;
    if (values.otp.length !== 6) {
      setHasErrored(true);
      isError = true;
    }
    if (values.emailOtp.length !== 6) {
      setHasEmailOtpErrored(true);
      isError = true;
    }
    if (isError) {
      return;
    }
    await onOtpSubmit(values);
  };

  const handleChange = (otp: string) => {
    setHasErrored(false);
    setFieldValue("otp", otp);
  };

  const handleEmailOtpChange = (emailOtp: string) => {
    setHasEmailOtpErrored(false);
    setFieldValue("emailOtp", emailOtp);
  };

  const onResendOtp = () => {
    setHasErrored(false);
    setTimer(60);
    setFieldValue("otp", "");
    setFieldValue("emailOtp", "");
    if (fromSignUp) handleSubmit();
    else {
      if (resendCode) {
        resendCode();
      }
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => {
          return null;
        }}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div
                className="w-full px-4 py-6 sm:px-20 sm:py-10"
                style={{
                  background: "linear-gradient(130deg,#29120A 0, #823920 100%)",
                }}
              >
                <div className="absolute top-5 right-5">
                  <Button
                    variant={ButtonVariant.Transparent}
                    className="!p-0"
                    onClick={() => setShow(false)}
                  >
                    <i className="text-white pi pi-times"></i>
                  </Button>
                </div>
                <div className="flex flex-col items-center mt-2 mb-8">
                  <div className="mb-2 text-base font-bold text-center text-white md:text-lg">
                    Please enter the 6-digit verification code we sent via
                    Email:
                  </div>
                  <div className="w-full">
                    <div className="relative flex justify-center w-full">
                      <OtpInput
                        value={values.emailOtp}
                        onChange={handleEmailOtpChange}
                        inputStyle={{
                          width: "1.5em",
                          height: "1.5em",
                          fontSize: "2em",
                          fontFamily: "Inter, sans-serif",
                          borderRadius: "0.25em",
                          border: "1px solid #823920",
                          boxShadow: "0 0 5px #ccc inset",
                        }}
                        inputType="tel"
                        renderSeparator={<span>&nbsp;</span>}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <div className="mb-2 text-base font-bold text-center text-white md:text-lg">
                    Enter the 6-digit verification code sent via SMS:
                  </div>
                  <div className="w-full">
                    <div className="relative flex justify-center w-full">
                      <OtpInput
                        value={values.otp}
                        onChange={handleChange}
                        inputStyle={{
                          width: "1.5em",
                          height: "1.5em",
                          fontSize: "2em",
                          fontFamily: "Inter, sans-serif",
                          borderRadius: "0.25em",
                          border: "1px solid #823920",
                          boxShadow: "0 0 5px #ccc inset",
                        }}
                        numInputs={6}
                        inputType="tel"
                        renderSeparator={<span>&nbsp;</span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>

                    {/* <div className="mt-4 text-sm">
                      <Link href="/forgot-password">
                        <a className="font-medium text-white underline underline-offset-1">
                          Forgot your password?
                        </a>
                      </Link>
                    </div> */}
                    <div className="mt-10">
                      <Button
                        type={ButtonType.Button}
                        variant={ButtonVariant.Orange}
                        className="w-full"
                        disabled={otpSubmitting}
                        loading={otpSubmitting}
                        onClick={() => onSubmit()}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-col items-center mt-4 text-sm text-center text-white">
                    Didn't received the codes?&nbsp;
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.Transparent}
                      size={ButtonSize.Small}
                      disabled={timer > 0}
                      onClick={onResendOtp}
                    >
                      Resend Codes {timer > 0 ? `(${timer})` : ""}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
