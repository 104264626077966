/** @format */

import {
  ExclamationCircleIcon,
  EyeIcon,
  EyeOffIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import { Tooltip } from "primereact/tooltip";
import React, { forwardRef, ReactNode, useEffect, useState } from "react";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode;
  subLabel?: string | ReactNode;
  error?: boolean;
  errorMessage?: string;
  touched?: boolean;
  variant?: "small" | "medium" | "large" | "responsive";
  className?: string;
  required?: boolean;
  labelClass?: string;
  color?: string;
  type?: string;
  informationToolTip?: string;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      subLabel,
      name,
      value,
      onChange,
      onBlur,
      placeholder = "",
      error = false,
      errorMessage = "",
      touched = false,
      variant = "medium",
      className,
      required = false,
      labelClass,
      color = "text-black",
      type = "text",
      informationToolTip,
      ...props
    },
    ref,
  ) => {
    const [variantValue, setVariantValue] = useState(variant);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
      const updateVariant = () => {
        if (window.innerWidth >= 768) {
          setVariantValue("large");
        } else {
          setVariantValue("medium");
        }
      };
      if (variant === "responsive") {
        // Initial check
        updateVariant();

        // Add event listener
        window.addEventListener("resize", updateVariant);

        // Cleanup event listener on component unmount
      }
      return () => window.removeEventListener("resize", updateVariant);
    }, [variant]);

    const inputClass = classNames(
      touched && error
        ? "border-red-500 focus:border-red-500"
        : "border-brown-500 focus:border-brown-500",
      "border placeholder-brown-700 placeholder-opacity-80 w-full rounded-md remove-number-arrows focus:[box-shadow:none]  focus:!outline-none bg-white disabled:opacity-50",
      {
        "p-1 py-1 pl-2 text-[12px] pr-2 leading-[18.4px]":
          variantValue === "small",
        "p-2 py-2 pl-2 text-[14px] leading-[20px] pr-2":
          variantValue === "medium",
        "p-3 py-3 pl-3 pr-3 sm:text-base": variantValue === "large",
        "!pr-4": variantValue === "small" && error,
        "!pr-8": variantValue === "medium" && error,
        "!pr-10": variantValue === "large" && error,
      },
      className,
    );

    const labelClassName = classNames(
      "block",
      {
        "text-sm mb-1": variantValue === "small",
        "text-base mb-1": variantValue === "medium",
        "text-base mb-2": variantValue === "large",
      },
      labelClass,
    );
    const subClassName = classNames("block italic", {
      "text-xs": variantValue === "small",
      "text-sm": variantValue === "medium" || variantValue === "large",
    });

    const errorIconClass = classNames(
      "absolute bottom-0 right-0 flex items-center cursor-pointer",
      {
        "w-3.5 h-3.5 mr-2.5 mb-[6.5px]": variantValue === "small",
        "w-4 h-4 mb-[10.5px] mr-2": variantValue === "medium",
        "w-5 h-5 mb-[14.5px] mr-3": variantValue === "large",
      },
    );

    const eyeIconClass = classNames(
      "absolute bottom-0 right-0 flex items-center cursor-pointer",
      {
        "w-3.5 h-3.5 mr-7 mb-[6.5px]": variantValue === "small",
        "w-4 h-4 mb-[10.5px] mr-8": variantValue === "medium",
        "w-5 h-5 mb-[14.5px] mr-10": variantValue === "large",
      },
    );

    return (
      <div className="relative w-full">
        {label && typeof label === "string" ? (
          <div className={labelClassName}>
            <div className={classNames(color, "flex align-bottom")}>
              {label}
              {required && "*"}
              {informationToolTip && (
                <span className="content-start self-start inline-block my-auto ml-2">
                  <Tooltip
                    target={`.generic-text-input-info-button`}
                    position="top"
                    className="max-w-sm text-sm"
                    content={informationToolTip}
                  ></Tooltip>
                  <InformationCircleIcon
                    className={`w-5 h-5 text-brown-300 generic-text-input-info-button`}
                    aria-hidden="true"
                  />
                </span>
              )}
            </div>
            {subLabel && typeof subLabel === "string" ? (
              <div>
                <span className={classNames(color, subClassName)}>
                  {subLabel}
                </span>
              </div>
            ) : (
              subLabel
            )}
          </div>
        ) : (
          label
        )}

        <input
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          className={inputClass}
          ref={ref}
          {...props}
        />
        {touched && error && (
          <div className={errorIconClass}>
            <Tooltip
              target={`.${name?.replace(".", "-")}-error`}
              position="top"
              className={classNames("red-tooltip", {
                "text-sm": variantValue === "small",
                "text-base":
                  variantValue === "medium" || variantValue === "large",
              })}
              content={errorMessage}
            ></Tooltip>
            <ExclamationCircleIcon
              className={`text-orange-500 ${name?.replace(".", "-")}-error`}
              aria-hidden="true"
            />
          </div>
        )}
        {type === "password" && (
          <div className={eyeIconClass}>
            <button
              type="button"
              onClick={(e) => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword && (
                <EyeIcon className={`w-5 h-5`} aria-hidden="true" />
              )}
              {!showPassword && (
                <EyeOffIcon className={`w-5 h-5`} aria-hidden="true" />
              )}
            </button>
          </div>
        )}
      </div>
    );
  },
);

export default TextInput;
