/** @format */

export const phoneFormatter = (input: string): string => {
  const value = input;
  let newvalue = "";

  if (input) {
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) {
      newvalue = phoneNumber.toString();
    } else if (phoneNumberLength < 7) {
      newvalue = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else {
      newvalue = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6,
      )}-${phoneNumber.slice(6, 10)}`;
    }
  }
  return newvalue;
};

export const ssnFormatter = (input: string): string => {
  let val = input.replace(/\D/g, "");
  val = val.replace(/^(\d{3})(\d{1,2})/, "$1-$2");
  val = val.replace(/^(\d{3})-(\d{2})(.+)/, "$1-$2-$3");
  return val.substring(0, 11);
};

export const dateOfBirthFormatter = (input: string): string => {
  // Remove any non-digit characters
  let val = input.replace(/\D/g, "");

  // Insert slashes after the month (MM) and day (DD)
  val = val.replace(/^(\d{2})(\d{1,2})/, "$1/$2");
  val = val.replace(/^(\d{2})\/(\d{2})(.+)/, "$1/$2/$3");

  // Limit the output to the first 10 characters (MM/DD/YYYY)
  return val.substring(0, 10);
};

export const dotFormatter = (input: string): string => {
  const value = input;
  let newvalue = "";

  const dot = value.replace(/[^\d]/g, "");
  newvalue = `USDOT ${dot.slice(0, 8)}`;
  return newvalue;
};

export const fuelFormatter = (input: string): string => {
  const value = input;
  let newvalue = "";

  const fuel = value.replace(/[^\d]/g, "");
  newvalue = `$${fuel.slice(0, 8)}`;
  return newvalue;
};

export const einFormatter = (input: string): string => {
  const value = input;
  let newvalue = "";

  const ein = value.replace(/[^\d]/g, "");

  const einLength = ein.length;
  if (einLength < 3) {
    newvalue = ein.toString();
  } else {
    newvalue = `${ein.slice(0, 2)}-${ein.slice(2, 9)}`;
  }
  return newvalue;
};

export const mcFormatter = (input: string): string => {
  const value = input;
  let newvalue = "";

  const mc = value.replace(/[^\d]/g, "");
  newvalue = `MC-${mc.slice(0, 7)}`;
  return newvalue;
};

export const dollarFormatter = (input: number): string => {
  let formattedDollar = "";
  if (input || input === 0) {
    formattedDollar = `$${input
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  }
  return formattedDollar;
};
